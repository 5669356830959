import { AxiosInstance } from 'axios';
import { LOCAL_STORAGE_KEY } from './initHttpService';
import { sendSentryData } from '../../../configs/sendSentryData';

export type Middleware = (instance: AxiosInstance) => void;

export enum responseStatus {
    BAD_REQUEST_ERROR = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    UNPROCESSABLE_ENTITY = 422,
    SERVER_ERROR = 500,
}

enum messageTypes {
    SUCCESS,
    VALIDATION_ERROR,
    SERVER_ERROR,
    LOG,
    INFO,
}

export const setMiddleware: Middleware = (instance) => {
    instance.interceptors.response.use(
        (response) => {
            const { SUCCESS, VALIDATION_ERROR, SERVER_ERROR } = messageTypes;
            if (response.data.messages && response.data.messages.length > 0) {
                for (let messageObj of response.data.messages) {
                    const { type, message } = messageObj;
                    switch (type) {
                        case SUCCESS:
                            break;
                        case VALIDATION_ERROR:
                            console.error({ type: 'error', message });
                            break;
                        case SERVER_ERROR:
                            console.error({ type: 'error', message });
                            break;
                    }
                }
            }
            return response;
        },
        (error) => {
            const {
                BAD_REQUEST_ERROR,
                FORBIDDEN,
                NOT_FOUND,
                UNPROCESSABLE_ENTITY,
                SERVER_ERROR,
                UNAUTHORIZED,
            } = responseStatus;
            if (error.response) {
                const { status } = error.response;
                switch (status) {
                    case FORBIDDEN:
                        console.error({
                            type: 'error',
                            message: 'Forbidden : Вам не разрешено производить данное действие.',
                        });
                        break;
                    case NOT_FOUND:
                        console.error('Page not found');
                        break;
                    case SERVER_ERROR:
                    case BAD_REQUEST_ERROR:
                    case UNAUTHORIZED:
                    case UNPROCESSABLE_ENTITY: {
                        sendSentryData({
                            data: error.response,
                            message: 'Failed request API',
                        });
                        localStorage.removeItem(LOCAL_STORAGE_KEY);
                        window.location.reload();
                    }
                }
            }
            return Promise.reject(error);
        },
    );

    return instance;
};
