import { FC } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { SpinSize } from 'antd/es/spin';

interface Props {
    size?: SpinSize;
}

export const Spinner: FC<Props> = ({ size = 'large' }) => (
    <Spin indicator={<LoadingOutlined style={{ color: '#FF6633' }} spin />} size={size} />
);
