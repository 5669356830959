import { create } from 'zustand';
import { Tasks } from '../../api-client/services/v1/TaskService/interfaces';
import serviceInitializer from '../../api-client/configs/serviceInitializer';
import { TaskService } from '../../api-client/services/v1/TaskService';
import { sendSentryData } from '../../../configs/sendSentryData';

interface TasksStoreData {
    tasks: Tasks;
    isLoading: boolean;
    isEmpty: boolean;
    isError: boolean;
    getTasks: (name?: string) => Promise<void>;
}

export const useTasksStore = create<TasksStoreData>((set) => {
    const taskServices = serviceInitializer<TaskService>(TaskService);

    const getTasks = async (name?: string) => {
        set({ isLoading: true });
        try {
            const response = await taskServices.getTasks({ name });
            if (!response.items.length) {
                set({ isEmpty: true });
            }

            set({ tasks: response.items });
        } catch (error) {
            console.error(error);
            set({ isError: true, isEmpty: true });
            sendSentryData({
                message: 'Failed to get task list',
            });
        } finally {
            set({ isLoading: false });
        }
    };

    return {
        tasks: [],
        isLoading: false,
        isEmpty: false,
        isError: false,
        getTasks,
    };
});
