import { FC } from 'react';
import { IconProps } from './interfaces';

export const LockIcon: FC<IconProps> = () => {
    return (
        <svg
            width="101"
            height="104"
            viewBox="0 0 101 104"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M50.5 103.875C78.1142 103.875 100.5 98.9781 100.5 92.9375C100.5 86.8969 78.1142 82 50.5 82C22.8858 82 0.5 86.8969 0.5 92.9375C0.5 98.9781 22.8858 103.875 50.5 103.875Z"
                fill="#F5F5F5"
            />
            <mask id="path-2-inside-1_56_6519" fill="white">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.5 43C20.8726 43 15.5 48.3726 15.5 55V81C15.5 87.6274 20.8726 93 27.5 93H73.5C80.1274 93 85.5 87.6274 85.5 81V55C85.5 48.3726 80.1274 43 73.5 43H27.5ZM50.5 67C53.5376 67 56 64.5376 56 61.5C56 58.4624 53.5376 56 50.5 56C47.4624 56 45 58.4624 45 61.5C45 64.5376 47.4624 67 50.5 67Z"
                />
            </mask>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.5 43C20.8726 43 15.5 48.3726 15.5 55V81C15.5 87.6274 20.8726 93 27.5 93H73.5C80.1274 93 85.5 87.6274 85.5 81V55C85.5 48.3726 80.1274 43 73.5 43H27.5ZM50.5 67C53.5376 67 56 64.5376 56 61.5C56 58.4624 53.5376 56 50.5 56C47.4624 56 45 58.4624 45 61.5C45 64.5376 47.4624 67 50.5 67Z"
                fill="white"
            />
            <path
                d="M17.5 55C17.5 49.4772 21.9772 45 27.5 45V41C19.768 41 13.5 47.268 13.5 55H17.5ZM17.5 81V55H13.5V81H17.5ZM27.5 91C21.9772 91 17.5 86.5229 17.5 81H13.5C13.5 88.732 19.768 95 27.5 95V91ZM73.5 91H27.5V95H73.5V91ZM83.5 81C83.5 86.5228 79.0229 91 73.5 91V95C81.232 95 87.5 88.732 87.5 81H83.5ZM83.5 55V81H87.5V55H83.5ZM73.5 45C79.0229 45 83.5 49.4772 83.5 55H87.5C87.5 47.268 81.232 41 73.5 41V45ZM27.5 45H73.5V41H27.5V45ZM54 61.5C54 63.433 52.433 65 50.5 65V69C54.6421 69 58 65.6421 58 61.5H54ZM50.5 58C52.433 58 54 59.567 54 61.5H58C58 57.3579 54.6421 54 50.5 54V58ZM47 61.5C47 59.567 48.567 58 50.5 58V54C46.3579 54 43 57.3579 43 61.5H47ZM50.5 65C48.567 65 47 63.433 47 61.5H43C43 65.6421 46.3579 69 50.5 69V65Z"
                fill="#D9D9D9"
                mask="url(#path-2-inside-1_56_6519)"
            />
            <circle cx="50.5" cy="61.5" r="6.5" stroke="#D9D9D9" strokeWidth="2" />
            <line x1="50.5" y1="67" x2="50.5" y2="79" stroke="#D9D9D9" strokeWidth="2" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.5 24.8675V43H27.5V26.0319C27.5 12.7593 37.7732 2 50.4454 2C63.1177 2 73.3909 12.7593 73.3909 26.0319L73.5 43H75.5L75.3775 24.8675C75.3775 11.1335 64.212 0 50.4387 0C36.6654 0 25.5 11.1335 25.5 24.8675Z"
                fill="#D9D9D9"
            />
        </svg>
    );
};
