import axios, { AxiosInstance } from 'axios';
import { env } from '../../../configs/env';
import { setMiddleware } from './apiMiddleware';
import { getJWT } from '../../../helpers/jwt';

export type InitHttpService = (apiUrl: string) => AxiosInstance;

export const JWT_KEY = 'auth';
export const LOCAL_STORAGE_KEY = 'ww_front_office_jwt';

export const initHttpService: InitHttpService = (apiUrl) => {
    const { jwt } = getJWT();

    const instance = axios.create({
        baseURL: apiUrl,
        headers: {
            'Authorization-Token': `${jwt}`,
        },
    });

    setMiddleware(instance);

    return instance;
};

export const httpService: AxiosInstance = initHttpService(env.server);
