import * as Sentry from '@sentry/react';

export const JWT_KEY = 'auth';
export const LOCAL_STORAGE_KEY = 'ww_front_office_jwt';

export type GetJWTFromUrl = (key: string) => string | undefined;
export const getJWTFromUrl: GetJWTFromUrl = (key) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const params: Record<string, string> = {};

    urlParams.forEach((value, key) => (params[key] = value));

    const jwt = params[key];
    if (!jwt || !jwt.length) {
        return undefined;
    }

    return jwt;
};

export interface GetJWTRes {
    jwt: string | undefined;
    jwtFromUrl: string | undefined;
    jwtFromLocalStorage: string | undefined;
}

export const getJWT = (): GetJWTRes => {
    const jwtFromUrl = getJWTFromUrl(JWT_KEY);
    const jwtFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEY) || undefined;

    if (!jwtFromUrl && !jwtFromLocalStorage) {
        Sentry.captureException(new Error('JWT was not found!'));
    }

    return {
        jwt: jwtFromUrl || jwtFromLocalStorage,
        jwtFromUrl,
        jwtFromLocalStorage,
    };
};

export const setJWT = (newJWT: string) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, newJWT);
};
