export interface StatusIndicators {
    isLoading: boolean;
    isEmpty: boolean;
    isError: boolean;
}

export const defaultIndicators: StatusIndicators = {
    isLoading: false,
    isEmpty: false,
    isError: false,
};

export const updateIndicators = (
    current: StatusIndicators,
    updates: Partial<StatusIndicators>,
): StatusIndicators => ({
    ...current,
    ...updates,
});
