import React from 'react';
import { Typography } from 'antd';
import { LockIcon } from '../../components/Icons';

import styles from './styles.module.css';

const { Title, Text } = Typography;

export const Error: React.FC = () => {
    return (
        <div className={styles.errorPage}>
            <LockIcon />
            <div className={styles.textContent}>
                <Title className={styles.title}>Доступ запрещен</Title>
                <Text type={'secondary'} className={styles.description}>
                    У вас нет доступа к этой странице
                </Text>
            </div>
        </div>
    );
};
