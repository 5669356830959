import { FC } from 'react';
import { IconProps } from './interfaces';

export const LeftArrowIcon: FC<IconProps> = ({ width = 20, height = 20 }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.7322 3.4442V1.71876C14.7322 1.5692 14.5603 1.48662 14.4443 1.57813L4.38176 9.43751C4.29627 9.50399 4.22709 9.58913 4.1795 9.68642C4.13191 9.78371 4.10718 9.89059 4.10718 9.99889C4.10718 10.1072 4.13191 10.2141 4.1795 10.3114C4.22709 10.4087 4.29627 10.4938 4.38176 10.5603L14.4443 18.4196C14.5626 18.5112 14.7322 18.4286 14.7322 18.279V16.5536C14.7322 16.4442 14.6809 16.3393 14.5961 16.2723L6.56033 10L14.5961 3.72545C14.6809 3.65849 14.7322 3.55358 14.7322 3.4442Z"
                fill="#666666"
            />
        </svg>
    );
};
