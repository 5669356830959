import { createBrowserRouter, Navigate } from 'react-router-dom';
import { Router as RemixRouter } from '@remix-run/router/dist/router';

import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { PagesPaths } from './paths';
import { Layout } from './Layout';
import * as Pages from '../pages';

export const router: RemixRouter = createBrowserRouter([
    {
        element: <Layout />,
        children: [
            {
                element: <PrivateRoute />,
                children: [
                    {
                        path: PagesPaths.TASK_LIST,
                        element: <Pages.TaskList />,
                    },
                    {
                        path: PagesPaths.TASK,
                        element: <Pages.TaskView />,
                    },
                ],
            },
            {
                element: <PublicRoute />,
                children: [
                    {
                        path: PagesPaths.ERROR,
                        element: <Pages.Error />,
                    },
                    {
                        path: PagesPaths.ROOT,
                        element: <Navigate to={PagesPaths.ROOT + PagesPaths.TASK_LIST} replace />,
                    },
                    {
                        path: '*',
                        element: <Pages.Error />,
                    },
                ],
            },
        ],
    },
]);
