import { FC } from 'react';
import { Typography } from 'antd';

import { LogoIcon } from '../../components/Icons';
import { getCurrentYear } from '../../helpers/getCurrentYear';
import { useNavigate } from 'react-router';
import { PagesPaths } from '../paths';

import styles from './styles.module.css';

const { Text } = Typography;

export const Header: FC = () => {
    const year = getCurrentYear();
    const navigate = useNavigate();
    return (
        <div className={styles.header}>
            <div className={styles.logo} onClick={() => navigate(PagesPaths.TASK_LIST)}>
                <LogoIcon />
            </div>
            <Text className={styles.companyName}>Wowworks @{year}</Text>
        </div>
    );
};
