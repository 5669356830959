import { AxiosInstance } from 'axios';
import { env } from '../../../configs/env';

export class BaseService {
    protected http: AxiosInstance;

    constructor(instance: AxiosInstance) {
        this.http = instance;
    }
}
