import { useCallback, useRef } from 'react';

export const useDebounce = (callback: Function, delay: number) => {
    const timer = useRef<NodeJS.Timeout | null>(null);

    return useCallback(
        (...args: any[]) => {
            const context = this;
            clearTimeout(timer.current as NodeJS.Timeout);
            timer.current = setTimeout(() => {
                callback.apply(context, args);
            }, delay);
        },
        [callback, delay],
    );
};
