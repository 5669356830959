import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthStore } from '../../modules/business-logic/Auth';
import { PagesPaths } from '../paths';

export const PrivateRoute: React.FC = () => {
    const { isAuthenticated } = useAuthStore();

    if (!isAuthenticated) {
        return <Navigate to={PagesPaths.ROOT + PagesPaths.ERROR} replace />;
    }

    return <Outlet />;
};
