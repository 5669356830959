import { create } from 'zustand';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

import { PlannedArrivalDate, Task } from '../../api-client/services/v1/TaskService/interfaces';
import serviceInitializer from '../../api-client/configs/serviceInitializer';
import { TaskService } from '../../api-client/services/v1/TaskService';
import { defaultIndicators, updateIndicators } from '../indicators';
import { sendSentryData } from '../../../configs/sendSentryData';

interface StatusIndicators {
    isLoading: boolean;
    isEmpty: boolean;
    isError: boolean;
}

interface TasksStoreData {
    task: Task;
    arrivalDate: PlannedArrivalDate;
    taskIndicators: StatusIndicators;
    arrivalDateIndicators: StatusIndicators;
    getTask: (id: number) => Promise<void>;
    getPlannedArrivalDate: (id: number) => Promise<void>;
    getTaskInfo: (id: number) => Promise<void>;
}

const defaultTask: Task = {
    id: 0,
    name: '',
    status: '',
};

export const useTaskStore = create<TasksStoreData>((set, get) => {
    const taskServices = serviceInitializer<TaskService>(TaskService);

    const getTask = async (id: number) => {
        set({
            taskIndicators: updateIndicators(get().taskIndicators, { isLoading: true }),
        });
        try {
            const task = await taskServices.getTask({ id });
            set({
                task,
                taskIndicators: updateIndicators(get().taskIndicators, {
                    isLoading: false,
                    isEmpty: !task,
                    isError: false,
                }),
            });
        } catch (error) {
            console.error(error);
            set({
                taskIndicators: updateIndicators(get().taskIndicators, {
                    isError: true,
                    isEmpty: true,
                }),
            });
            sendSentryData({
                message: `Failed to get task ${id}`,
            });
        } finally {
            set({
                taskIndicators: updateIndicators(get().taskIndicators, { isLoading: false }),
            });
        }
    };

    const getPlannedArrivalDate = async (id: number) => {
        set({
            arrivalDateIndicators: updateIndicators(get().arrivalDateIndicators, {
                isLoading: true,
            }),
        });
        try {
            const arrivalDate = await taskServices.getPlannedArrivalDate({ id });
            const formattedArrivalDate = arrivalDate
                ? {
                      ...arrivalDate,
                      dateTime: format(new Date(arrivalDate.dateTime), 'dd MMM yyyy, HH:mm', {
                          locale: ru,
                      }),
                  }
                : null;
            set({
                arrivalDate: formattedArrivalDate,
                arrivalDateIndicators: updateIndicators(get().arrivalDateIndicators, {
                    isLoading: false,
                    isEmpty: !arrivalDate,
                    isError: false,
                }),
            });
        } catch (error) {
            console.error(error);
            set({
                arrivalDateIndicators: updateIndicators(get().arrivalDateIndicators, {
                    isError: true,
                    isEmpty: true,
                }),
            });
            sendSentryData({
                message: `Failed to get arrival date of task ${id}`,
            });
        } finally {
            set({
                arrivalDateIndicators: updateIndicators(get().arrivalDateIndicators, {
                    isLoading: false,
                }),
            });
        }
    };

    const getTaskInfo = async (id: number) => {
        await getTask(id);
        await getPlannedArrivalDate(id);
    };

    return {
        task: defaultTask,
        taskIndicators: defaultIndicators,
        arrivalDateIndicators: defaultIndicators,
        getTask,
        getPlannedArrivalDate,
        arrivalDate: null,
        getTaskInfo,
    };
});
