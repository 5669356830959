import React, { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Card, Typography } from 'antd';

import { SEARCH_PARAM_TASK_ID } from '../../constants ';
import { useTaskStore } from '../../modules/business-logic/Task';
import { PagesPaths } from '../../router/paths';
import { LoadingData } from '../../components/LoadingData';
import { EmptyTaskCard, TaskCard } from './components/TaskCard';
import { LeftArrowIcon } from '../../components/Icons';

import styles from './styles.module.css';

const { Text } = Typography;

export const TaskView: React.FC = () => {
    const [searchParams] = useSearchParams();
    const taskId = searchParams.get(SEARCH_PARAM_TASK_ID);
    const { taskIndicators, task, getTaskInfo, arrivalDate } = useTaskStore();

    const isTaskExist = !taskIndicators.isEmpty && !taskIndicators.isLoading;

    useEffect(() => {
        if (taskId) {
            getTaskInfo(+taskId);
        }
    }, [taskId, getTaskInfo]);

    return (
        <LoadingData
            isLoading={taskIndicators.isLoading}
            spinnerContentStyles={styles.loaderContainer}
        >
            <div className={styles.task}>
                <Link to={PagesPaths.ROOT + PagesPaths.TASK_LIST} className={styles.backLink}>
                    <LeftArrowIcon />
                    <Text className={styles.backLinkText}>Вернуться к заданиям</Text>
                </Link>
                <Card className={styles.card}>
                    <div className={styles.cardContent}>
                        {!isTaskExist && <EmptyTaskCard />}
                        {isTaskExist && (
                            <TaskCard
                                taskId={taskId}
                                status={task.status}
                                name={task.name}
                                dateTime={arrivalDate?.dateTime}
                                timezone={arrivalDate?.timezone}
                            />
                        )}
                    </div>
                </Card>
            </div>
        </LoadingData>
    );
};
