import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Empty, Table } from 'antd';

import { Task, Tasks } from '../../../modules/api-client/services/v1/TaskService/interfaces';
import { PagesPaths } from '../../../router/paths';
import { SEARCH_PARAM_TASK_ID } from '../../../constants ';

import styles from './styles.module.css';

interface Props {
    tasks: Tasks;
}

export const TasksTable: FC<Props> = ({ tasks }) => {
    const columns = [
        {
            title: <span className={styles.customTableHeader}>ID</span>,
            dataIndex: 'id',
            key: 'id',
            width: '15%',
        },
        {
            title: <span className={styles.customTableHeader}>Название задания</span>,
            dataIndex: 'name',
            key: 'name',
            width: '85%',
            render: (_: any, record: Task) => {
                const redirectUrl = `${PagesPaths.ROOT + PagesPaths.TASK}?${SEARCH_PARAM_TASK_ID}=${
                    record.id
                }`;
                return (
                    <Link to={redirectUrl} className={styles.taskLink}>
                        {record.name}
                    </Link>
                );
            },
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={tasks}
            rowKey={'id'}
            pagination={false}
            rowClassName={() => styles.customTableRow}
            locale={{
                emptyText: (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Нет данных'} />
                ),
            }}
        />
    );
};
