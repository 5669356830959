import { FC } from 'react';
import { IconProps } from './interfaces';

export const SearchIcon: FC<IconProps> = ({ width = 14, height = 14 }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_116_752)">
                <path
                    d="M13.2127 12.3535L9.15493 8.2957C9.78462 7.48164 10.1252 6.48633 10.1252 5.43945C10.1252 4.18633 9.63618 3.01133 8.75181 2.12539C7.86743 1.23945 6.68931 0.751953 5.43774 0.751953C4.18618 0.751953 3.00806 1.24102 2.12368 2.12539C1.23774 3.00977 0.750244 4.18633 0.750244 5.43945C0.750244 6.69102 1.23931 7.86914 2.12368 8.75352C3.00806 9.63945 4.18462 10.127 5.43774 10.127C6.48462 10.127 7.47837 9.78633 8.29243 9.1582L12.3502 13.2145C12.3621 13.2264 12.3763 13.2358 12.3918 13.2422C12.4074 13.2487 12.424 13.252 12.4409 13.252C12.4577 13.252 12.4744 13.2487 12.4899 13.2422C12.5055 13.2358 12.5196 13.2264 12.5315 13.2145L13.2127 12.5348C13.2246 12.5229 13.2341 12.5087 13.2405 12.4932C13.247 12.4776 13.2503 12.461 13.2503 12.4441C13.2503 12.4273 13.247 12.4106 13.2405 12.3951C13.2341 12.3795 13.2246 12.3654 13.2127 12.3535ZM7.91274 7.91445C7.25024 8.57539 6.37212 8.93945 5.43774 8.93945C4.50337 8.93945 3.62524 8.57539 2.96274 7.91445C2.30181 7.25195 1.93774 6.37383 1.93774 5.43945C1.93774 4.50508 2.30181 3.62539 2.96274 2.96445C3.62524 2.30352 4.50337 1.93945 5.43774 1.93945C6.37212 1.93945 7.25181 2.30195 7.91274 2.96445C8.57368 3.62695 8.93774 4.50508 8.93774 5.43945C8.93774 6.37383 8.57368 7.25352 7.91274 7.91445Z"
                    fill="black"
                    fillOpacity="0.45"
                />
            </g>
            <defs>
                <clipPath id="clip0_116_752">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
