import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import { RouterProvider } from 'react-router-dom';
import { setFavicon } from './helpers/setFavicon';
import { initApp } from './init';
import { router } from './router';

import './index.css';

setFavicon();
initApp();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        cellPaddingBlock: 26,
                    },
                    Typography: {
                        titleMarginBottom: 0,
                    },
                },
            }}
        >
            <RouterProvider router={router} />
        </ConfigProvider>
    </React.StrictMode>,
);
