import { FC, ReactNode } from 'react';

import { Spinner } from '../Spinner';

import styles from './styles.module.css';

interface Props {
    isLoading: boolean;
    children: ReactNode;
    spinnerContentStyles?: string;
}

export const LoadingData: FC<Props> = ({ isLoading, children, spinnerContentStyles = '' }) => {
    return (
        <div className={`${styles.content} ${spinnerContentStyles}`}>
            {isLoading ? (
                <div className={styles.spinnerBlock}>
                    <Spinner />
                </div>
            ) : (
                children
            )}
        </div>
    );
};
