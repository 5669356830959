import * as Sentry from '@sentry/react';

interface Params {
    data?: Object;
    message: string;
}

type SendSentryData = (params: Params) => void;

export const sendSentryData: SendSentryData = (params) => {
    Sentry.captureException(new Error(params.message), {
        extra: params as unknown as Record<string, unknown>,
    });
};
