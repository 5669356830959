import { create } from 'zustand';
import { getJWT } from '../../../helpers/jwt';

interface AuthStoreData {
    isAuthenticated: boolean;
}

export const useAuthStore = create<AuthStoreData>((set) => {
    const { jwt } = getJWT();

    set({
        isAuthenticated: !!jwt,
    });

    return {
        isAuthenticated: !!jwt,
    };
});
