import { httpService } from '../../../configs/initHttpService';
import { BaseService } from '../../BaseService';
import { BaseResponse } from '../../interfaces';
import {
    GetTaskParams,
    PlannedArrivalDateResponse,
    TaskResponse,
    TasksResponse,
} from './interfaces';

export class TaskService extends BaseService {
    readonly getTasks = (params: Partial<GetTaskParams>) =>
        this.http
            .get<BaseResponse<TasksResponse>>(`/v1/organization/external/tasks`, {
                params,
            })
            .then((res) => res.data.response);

    readonly getTask = ({ id }: Partial<GetTaskParams>) =>
        this.http
            .get<BaseResponse<TaskResponse>>(`/v1/organization/external/task/${id}`)
            .then((res) => res.data.response);

    readonly getPlannedArrivalDate = ({ id }: Partial<GetTaskParams>) =>
        this.http
            .get<
                BaseResponse<PlannedArrivalDateResponse>
            >(`/v1/organization/external/task/${id}/arrivals/planned`)
            .then((res) => res.data.response);
}

export default new TaskService(httpService);
