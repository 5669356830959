import React from 'react';
import { Empty, Typography } from 'antd';
import { SubInfo } from '../SubInfo';

import styles from '../../styles.module.css';

const { Text, Title } = Typography;

interface Props {
    taskId: string | null;
    name: string;
    status?: string;
    dateTime: string | undefined;
    timezone: string | undefined;
}

export const TaskCard: React.FC<Props> = (props) => {
    const { taskId, name, status, dateTime, timezone } = props;
    return (
        <>
            <Text className={styles.taskId}>ID {taskId}</Text>
            <Title className={styles.taskTitle}>{name}</Title>

            <div className={styles.divider} />

            <SubInfo title={'Статус'} value={status || 'Нет данных'} />

            <SubInfo
                title={'Прибытие исполнителя'}
                value={dateTime || 'Не указано'}
                addedValue={timezone}
            />
        </>
    );
};

export const EmptyTaskCard: React.FC = () => {
    return (
        <>
            <div className={styles.emptyBlock}>
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                        <div>
                            <Text type={'secondary'}>
                                Нет данных
                            </Text>
                        </div>
                    }
                />
            </div>
        </>
    );
};
