import React, { ChangeEvent, useEffect, useState } from 'react';
import { Input } from 'antd';

import { useTasksStore } from '../../modules/business-logic/Tasks';
import { SearchIcon } from '../../components/Icons';
import { LoadingData } from '../../components/LoadingData';
import { TasksTable } from './components/Table';
import { useDebounce } from '../../hooks/useDebounce';

import styles from './styles.module.css';

export const TaskList: React.FC = () => {
    const [value, setValue] = useState('');
    const { isLoading, getTasks, tasks } = useTasksStore();
    const debouncedGetTasks = useDebounce(getTasks, 500);

    useEffect(() => {
        getTasks();
    }, []);

    const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        const searchWithoutIndentation = event.target.value.trim();
        debouncedGetTasks(searchWithoutIndentation);
    };

    return (
        <div className={styles.content}>
            <div className={styles.pageTitle}>Задания</div>
            <section className={styles.tableContent}>
                <Input
                    placeholder={'Поиск по названию'}
                    suffix={<SearchIcon />}
                    className={styles.input}
                    value={value}
                    onChange={onSearch}
                />
                <LoadingData isLoading={isLoading}>
                    <TasksTable tasks={tasks} />
                </LoadingData>
            </section>
        </div>
    );
};
