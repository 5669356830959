import { FC } from 'react';
import { Typography } from 'antd';

import styles from './styles.module.css';

const { Text } = Typography;

interface Props {
    title: string;
    value: string;
    addedValue?: string;
}

export const SubInfo: FC<Props> = ({ title, value, addedValue }) => {
    return (
        <div className={styles.subInfo}>
            <Text type="secondary" className={styles.title}>
                {title}
            </Text>
            <div className={styles.values}>
                <Text className={styles.value}>{value}</Text>
                {addedValue && <Text className={styles.addedValue}>({addedValue})</Text>}
            </div>
        </div>
    );
};
