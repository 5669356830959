export interface Environment {
    authToken: string;
    server: string;
    fileServer: string;
    sentryUrl: string;
}

export const env: Environment = {
    authToken: process.env.REACT_APP_AUTH_TOKEN as string,
    server: process.env.REACT_APP_SERVER as string,
    fileServer: process.env.REACT_APP_FILE_SERVER as string,
    sentryUrl: process.env.SENTRY_URL as string,
};
